import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import SearchListTable from './search-list-table';
import Breadcrumbs from '../components/breadcrumbs';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { forEach } from 'lodash';

const ReplaceSeachComponents = () => {

    const selectRef = useRef(); 
    const [showSpinner, setShowSpinner] = useState(false);
    const [courseId, setCourseId] = useState(0);
    const [courseName, setCourseName] = useState();
    const [course, setCourse] = useState();
    const [courseArray, setCourseArray] = useState([]);
    const [unitArray, setUnitArray] = useState([]);
    const [lessonArray, setLessonArray] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [unitId, setUnitId] = useState();
    const [unitTitle, setUnitTitle] = useState();
    const [query, setQuery] = useState();
    const [maxResult, setMaxResult] = useState('');
    const [lessonContent, setLessonContent] = useState('');
    const [lessonId, setLessonId] = useState('');
    const [lessonName, setLessonName] = useState('');

    const [settingss, setSettingss] = useState({});
    const [minView, setMinView] = useState('');
    const [MinLike, setMinLike] = useState('');
    const [relavacyPrompt, setRelavacyPrompt] = useState('');

    const [saveChecked, setSaveChecked] = useState(false);

    const [preferredChecked, setPreferredChecked] = useState(false);

    const [searchSource, handleSearchSource] = useState('');
    const [pingurl, setPingUrl] = useState('');

    const [pingData, setPingData] = useState([]);
    const [videoData, setVideoData] = useState([]);
    const [lessonVideoUrls, setLessonVideoUrls] = useState([]);
    const [culData, setculData] = useState([]);
    const [prefferdChannelList, setPrefferdChannelList] = useState([]);
    const [selectedPreChannels, setSelectedPreChannels] = useState([]);
    const [savedkey, setSavedkey] = useState('');
    const [processStatus, setProcessStatus] = useState(false);
    const [profile, setProfile] = useState('');
    const [searchId, setSearchId] = useState('');

    // let searchId = localStorage.getItem('search_id') ? localStorage.getItem('search_id') : 0
    const navigate = useNavigate();
    const url = `${process.env.REACT_APP_API_URL}/video-finder-search`;
    useEffect(() => {
        let formData_1 = localStorage.getItem('tokenData')
        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?moodlewsrestformat=json&wsfunction=core_webservice_get_site_info', formData_1)
            .then(userdata => {
                if (!userdata.data.userid) {
                    localStorage.setItem('login', false)
                    navigate('/');
                }
            })
            .catch(error => {
                console.log(error)
            });
    }, [])
    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        const [id, name] = selectedValue.split('%%');
        setCourseId(id);
        setCourse(e.target.value);
        setCourseName(name);
    };
    const handleDescriptionChange = (e) => {
        setLessonContent(e.target.value)
    };

    const handleRelavencyChange = (e) => {
        setRelavacyPrompt(e.target.value)
    };

    const handleUnitChange = (e) => {
        const selectedValue = e.target.value;
        const [id, title] = selectedValue.split('%%');
        setUnitId(id);
        setUnitTitle(title);
    };
    const handleLessonChange = (e) => {
        const selectedValue = e.target.value;
        const [id, title, content] = selectedValue.split('%%');
        setLessonId(id);
        setLessonName(title);
        setLessonContent(htmlConverter(content))
        // const youtubeURLs = extractYouTubeURLs(content);
        //         // console.log(extractYouTubeURLs(content));
        // console.log(content);

        const youtubeURLs = extractYouTubeVideoIds(content);
        // console.log(youtubeURLs);
        setLessonVideoUrls(youtubeURLs);
    }

    function extractYouTubeVideoIds(text) {
        const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/g;
        const videoIds = [];
        let match;

        while ((match = regex.exec(text)) !== null) {
            videoIds.push(match[1]); // Push the video ID (the first capturing group)
        }

        return videoIds;
    }


    useEffect(() => {
        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_course_data&moodlewsrestformat=json')
            .then((response) => {
                setCourseArray(response.data.data);
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);
    useEffect(() => {
        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_course_unit_data&moodlewsrestformat=json&course_id=' + courseId)
            .then((response) => {
                if (response.data) {
                    const decodedData = JSON.parse(response.data.data);
                    let units = decodedData[0].units;
                    setUnitArray(validateUnits(units));
                }
            })
            .catch((err) => {
                console.log(err)
            })
        setPrefferdChannelList([]);
    }, [courseId])
    useEffect(() => {
        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_unit_lessions_data&moodlewsrestformat=json&unit_id=' + unitId)
            .then((response) => {
                if (response.data) {
                    setLessonArray(response.data.data);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [unitId])
    const handleReset = () => {
        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_course_data&moodlewsrestformat=json')
            .then((response) => {
                setCourseArray(response.data.data);
                setQuery('');
                setCourseId(0);
                setCourseName();
                setUnitId('');
                setUnitTitle('');
                setUnitArray([]);
                setCourse('');
                setLessonId('');
                setLessonContent('');
                setLessonArray([]);
                setLessonName('');
                setPreferredChecked(false)
                setMinLike('');
                setMinView('');
                setPingUrl('');
                setPingData([]);
                setculData([]);
                handleSearchSource('');
                selectRef.current.clearValue(); 
                setSaveChecked(false);
                setSavedkey('');
                setProfile('');
            })
    };
    const handleScrolDown = () => {
        window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
        });
    }
    const handleClick = () => {
        if (courseId && unitId && query && maxResult && lessonContent && lessonId) {
            if (maxResult > 50) {
                toast.error('max result should not be morethan 50')
            } else {
                setShowSpinner(true);
                const params = {
                    query: query,
                    course_id: courseId,
                    course_name: courseName,
                    unit_id: unitId,
                    unit_title: unitTitle,
                    lesson_id: lessonId,
                    lesson_name: lessonName,
                    lesson_content: lessonContent,
                    max_result: maxResult,
                    preferred_channels: selectedPreChannels,
                    relevency_prompt: relavacyPrompt,
                    key_word: savedkey,
                    minimum_like: MinLike != '' ? MinLike : 0,
                    minimum_view: minView != '' ? minView : 0,
                    save_search: savedkey ? true : false,
                    video_url: pingurl,
                    replacement_search: true
                };


                axios.post(url, params, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }).then(response => {
                   setSearchId(response.data.data.search_id)
                    // localStorage.setItem('search_id', response.data.data)
                    // setTableData(response.data.data)
                        if(response.data.data.count > 0){
                            toast.success(`Fetched ${response.data.data.count} data.`)
                    }
                    setShowSpinner(false);
                    handleReset();
                    handleScrolDown();
                })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
            }
        } else {
            toast.error('Please complete all fields - they are mandatory.')
        }
    }
    useEffect(() => {
        getSearchedList()
    }, [searchId])
    const getSearchedList = () => {
        axios.put(`${url}?search_id=${searchId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(response => {
                setTableData(response.data.data);
            })
            .catch(error => {
                console.log("error", error)
            })
    }
    const checkMaxResult = (value) => {
        const numRejex = /^[0-9]*$/

        if (numRejex.test(value)) {
            setMaxResult(value)
        }
    }

    const checkMinLike = (value) => {
        const numRejex = /^[0-9]*$/

        if (numRejex.test(value)) {
            setMinLike(value)
        }
    }
    const checkMinView = (value) => {
        const numRejex = /^[0-9]*$/

        if (numRejex.test(value)) {
            setMinView(value)
        }
    }

    const validateUnits = (data) => {
        const temp = data.slice()
        const val = temp.filter((unit) => (unit.name.trim() !== "") && unit.name != '.');
        return val
    }
    const htmlConverter = (data) => {
        var tempDiv = document.createElement('div');
        tempDiv.innerHTML = data;
        var plainText = tempDiv.textContent || tempDiv.innerText;
        const parser = new DOMParser();
        const doc = parser.parseFromString(plainText, 'text/html');
        const textContent = doc.body.textContent || '';
        return textContent
    }


    useEffect(() => {
        getSettings();
        getPingSource();
    }, [])


    useEffect(() => {

        if (searchSource === "ping-report") { getPingSource(); }

    }, [searchSource])

    const getSettings = () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/video-finder-settings`;

        axios.get(apiUrl)
            .then(response => {
                setSettingss(response.data.data);
                setMaxResult(response.data.data.maximum_result);
                setRelavacyPrompt(response.data.data.replacement_search_prompt);
            })
            .catch(error => {
                console.log("error", error)
            })

    }
    const handleSaveChange = () => {
        setSaveChecked(!saveChecked);
    };

    const handlepreferChange = () => {
        setSaveChecked(!saveChecked);
    };

    const pingUrlChange = (event) => {
    
        setPingUrl(event.target.value);
    };

    useEffect(() => {
        if(searchSource !== "course-content" ){
            setLessonContent('')
            getUrlDetails();
        }
       

    }, [pingurl])


    const getUrlDetails = () => {

        if (pingurl) {

            const match = pingurl.match(/embed\/([^?]+)/);
            if (match) {
                const videoId = match[1];
                const cleanUrl = `https://www.youtube.com/embed/${videoId}`;
                axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_getvideodetails&moodlewsrestformat=json&url=' + cleanUrl)
                    .then((response) => {
                        if (response.data.data == "No data found") {
                            toast.error('No Data Found...');
                        } else {
                            setVideoData(JSON.parse(response.data.data))
                        }

                    })
                    .catch((err) => {
                        console.log(err)
                    })
            } else {
                toast.error('Invalid URL');
            }


        }
    }

    useEffect(() => {

        if (videoData) {
            Object.entries(videoData).map(async ([key, vd]) => {

                // getculDetails(vd.course_id, vd.unit_id, vd.lesson_id);
                setQuery(vd.video_title);

                axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_unit_lessions_data&moodlewsrestformat=json&unit_id=' + vd.unit_id)
                    .then((response) => {
                        if (response.data) {
                            response.data.data.map(dat => {
                                if (dat.Id === Number(vd.lesson_id)) {
                                    setLessonContent(htmlConverter(dat.Lession_Content));
                                    setCourseId(vd.course_id);
                                    setUnitId(vd.unit_id);
                                    setLessonId(vd.lesson_id);

                                    getculDetails(vd.course_id, vd.unit_id, vd.lesson_id);

                                    // axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_get_cul_name&moodlewsrestformat=json&course=' + vd.course_id + '&unit=' + vd.unit_id + '&lesson=' + vd.lesson_id)
                                    //     .then((response) => {

                                    //         setculData(JSON.parse(response.data.data));
                                    //         //  console.log("cld",JSON.parse(response.data.data));

                                    //         setCourseName(JSON.parse(response.data.data).course_name);
                                    //         setUnitTitle(JSON.parse(response.data.data).unit_name);
                                    //         setLessonName(JSON.parse(response.data.data).lesson_name);
                                    //         // console.log(JSON.parse(response.data.data).course_name);


                                    //     })
                                    //     .catch((err) => {
                                    //         console.log(err)
                                    //     })

                                }
                            })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            });
        }
    }, [videoData])

    const getculDetails = (course, unit, lesson) => {

        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_get_cul_name&moodlewsrestformat=json&course=' + course + '&unit=' + unit + '&lesson=' + lesson)
            .then((response) => {

                setculData(JSON.parse(response.data.data));
                //  console.log("cld",JSON.parse(response.data.data));

                setCourseName(JSON.parse(response.data.data).course_name);
                setUnitTitle(JSON.parse(response.data.data).unit_name);
                setLessonName(JSON.parse(response.data.data).lesson_name);
                // console.log(JSON.parse(response.data.data).course_name);


            })
            .catch((err) => {
                console.log(err)
            })

    }

    const getPingSource = () => {

        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_ping_bad_videos&moodlewsrestformat=json')
            .then((response) => {
                setPingData(JSON.parse(response.data.data))
            })
            .catch((err) => {
                console.log(err)
            })

    }
    const handlePreferredChange = () => {
        setPreferredChecked(!preferredChecked);
    };
    const handleChannelChange = (selectedOptions) => {
        setSelectedPreChannels(selectedOptions);

    };
    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: '100%', // Set the desired width here
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#e0e0e0',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#000',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: 'red',
            ':hover': {
                backgroundColor: 'lightgrey',
                color: 'white',
            },
        }),
    };
    const getPreffered = (courseId) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/video-finder-preferred-channel`;
        const params = {
            course_id: courseId
        };
        axios.get(apiUrl, { params })
            .then(response => {
                const options = response.data.data.map(channel => ({
                    value: channel.custom_url,
                    label: channel.title
                }));

                setPrefferdChannelList(options);
            })
            .catch(error => {
                console.log("error", error)
            })

    }
    useEffect(() => {
        if (preferredChecked) {
            if (courseId) {
                getPreffered(courseId);
            } else {
                toast.error('Please select a course before select channel.')
            }
        }


    }, [preferredChecked])

    const handleSavekeyChange = (e) => {
        setSavedkey(e.target.value);
    };
    const [options, setOptions] = useState([]);
    const [searchKey, setSearchKey] = useState('');
    const [unit, setUnit] = useState();
    const [lesson, setLesson] = useState();
    const [form_data, setForm_data] = useState({});
    

    const getProfile = (searchKey) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/video-finder-search`;
        const params = {
            key_word: searchKey
        };
        axios.get(apiUrl, { params })
            .then(response => {
                // setProfileOpt(response.data.data);
                // setFilteredOptions(response.data.data);


                const formattedOptions = response.data.data.map(item => ({
                    value: item.id, // Adjust based on your data structure
                    label: item.key_word // Adjust based on your data structure
                }));
                setOptions(formattedOptions);

            })
            .catch(error => {
                console.log("error", error)
            })
    }
    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, ''); // Optionally sanitize input
        setSearchKey(inputValue);

        if (inputValue) {
            getProfile(inputValue); // Fetch data on input change
        } else {
            setOptions([]); // Clear options if input is empty
        }
    };
    const getAllProfile = () =>{
        const apiUrl = `${process.env.REACT_APP_API_URL}/video-finder-search`;
        axios.get(apiUrl)
            .then(response => {
                // setProfileOpt(response.data.data);
                // setFilteredOptions(response.data.data);


                const formattedOptions = response.data.data.map(item => ({
                    value: item.id, // Adjust based on your data structure
                    label: item.key_word // Adjust based on your data structure
                }));
                setOptions(formattedOptions);

            })
            .catch(error => {
                console.log("error", error)
            })
    }

    // const [uniqueCourseIds, setUniqueCourseIds] = useState([]);

    // useEffect(() => {
    //     const uniqueIds = new Set();
    //     const ids = [];

    //     culData.forEach(item => {
    //         if (!uniqueIds.has(item.course_id)) {
    //             uniqueIds.add(item.course_id);
    //             ids.push(item.course_id);
    //         }
    //     });

    //     setUniqueCourseIds(ids);
    // }, [culData]);

    useEffect( () => {
        setCourse(`${culData.course_id}%%${culData.course_name}`);
        setCourseId(culData.course_id);
        setUnit(`${culData.unit_id}%%${culData.unit_name}`)
        setUnitId(culData.unit_id);
        setLessonId(culData.lesson_id);
        // setLesson(`${culData.lesson_id}%%${culData.lesson_name}`)

        //selectedLessonId ? `${selectedLessonId}%%${lessonArray.find(item => item.Id === selectedLessonId).Lession_Name}%%${lessonArray.find(item => item.Id === selectedLessonId).Lession_Content}` : ''}
        

    }, [culData])

    useEffect(()=>{
        if (lessonArray && culData)  {
            
            const less=lessonArray.find(item => item.Id == culData.lesson_id);
            if(less){
                setLesson(less.Id+"%%"+less.Lession_Name+"%%"+less.Lession_Content);
            
            
            }
            

        }
    },[lessonArray])

    const setForm = async (prop) => {
        if (prop) {
            const apiUrl = `${process.env.REACT_APP_API_URL}/video-finder-search`;
            const params = {
                id: prop.value
            };
            axios.get(apiUrl, { params })
                .then(response => {
    
                    if (response.data.data) {
                        setCourseId(response.data.data[0].course_id);
                        setForm_data(response.data.data[0]);
                        getculDetails(response.data.data[0].course_id, response.data.data[0].unit_id, response.data.data[0].lesson_id);
                        setLessonContent(response.data.data[0].lesson_content);
                        setMaxResult(response.data.data[0].maximum_result);
                        setMinLike(response.data.data[0].minimum_like ? response.data.data[0].minimum_like : '');
                        setMinView(response.data.data[0].minimum_view ? response.data.data[0].minimum_view : '');
                        setQuery(response.data.data[0].query);
                        setRelavacyPrompt(response.data.data[0].relevancy_prompt);

                        
                        if(JSON.parse(response.data.data[0].preferred_channel).length){
                            setSelectedPreChannels(JSON.parse(response.data.data[0].preferred_channel))
                            setPreferredChecked(true)
                        }
                    }
    
                })
        }
    }
    useEffect(()=>{
        const timer = setTimeout(() => {
            if(processStatus){
                getSearchedList();
                setProcessStatus(false);
            }            
          }, 10000); 
    },[processStatus])
    return (
        <section className="video-search-section">
            <Container fluid className='px-4'>
                <Row>
                    <Col xs="12">
                        <div className="search-form-container flex-1 py-3">
                            <Breadcrumbs />
                            <p className="section-title mb-2">Replace Video Search</p>
                            <div className="form-block mt-3">
                                <div className="d-flex gap-3 flex-wrap">
                                    <Form style={{ width: "100%" }}>

                                    </Form>
                                    <Form style={{ maxWidth: "30%", width: "100%" }}>
                                        <Col>
                                            <Row>
                                                <Form.Group className="pb-2" controlId="source">
                                                    <Form.Select
                                                        value={searchSource}
                                                        onChange={(e) => { handleSearchSource(e.target.value); setPingUrl(''); setLessonContent('');setculData([]);  setQuery(''); }}>
                                                        <option>Select source</option>
                                                        <option key={"course-content"} value={"course-content"}>
                                                            Course content
                                                        </option>
                                                        <option key={"ping-report"} value={"ping-report"}>
                                                            Ping Report
                                                        </option>
                                                        <option key={"url"} value={"url"}>
                                                            URL
                                                        </option>

                                                    </Form.Select>
                                                </Form.Group>
                                                {searchSource !== "course-content" ? <p className='mt-2'>{culData.course_name}</p> : <Form.Group className="pb-2 mt-2" controlId="course">
                                                    {unitArray && <Form.Select
                                                        aria-label="Default select example"
                                                        onChange={handleUnitChange}
                                                        disabled={searchSource !== "course-content"}
                                                    >
                                                        <option>Select unit</option>
                                                        {unitArray.map(unit => (
                                                            <option key={unit.id} value={`${unit.id}%%${unit.name}`}>
                                                                {unit.name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>}
                                                </Form.Group>}


                                                <Form.Group className="py-2" controlId="courseModuleDescription">
                                                    <Form.Control as="textarea" placeholder="Course Module Description" style={{ height: '180px', maxWidth: '100%' }} value={lessonContent} onChange={handleDescriptionChange} />
                                                </Form.Group>
                                                <Form.Group className="mb-4" controlId="searchQueryString">
                                                    <Form.Control type="text" value={query} placeholder="Search Query String" onChange={(e) => { setQuery(e.target.value) }} autoComplete="off" />
                                                </Form.Group>
                                            </Row>
                                        </Col>
                                    </Form>
                                    <Form style={{ maxWidth: "30%", width: "100%" }}>
                                        {searchSource === "ping-report" && (
                                            pingData && <Form.Group controlId="ping-url" className='d-flex align-items-center pb-2'>
                                                <Form.Select
                                                    onChange={pingUrlChange}
                                                >
                                                    <option>Select url</option>

                                                    {pingData.map(ping => (
                                                        <option key={ping.id} value={ping.url}>
                                                            {ping.url}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        )}

                                        {searchSource === "url" && (
                                            <Form.Group controlId="url-textbox" className='d-flex align-items-center pb-2'>
                                                <Form.Control
                                                    type="text"
                                                    placeholder='Enter URL'
                                                    style={{ maxWidth: "100%" }}
                                                    value={pingurl}
                                                    onChange={pingUrlChange}
                                                />
                                            </Form.Group>
                                        )}

                                        {searchSource === "course-content" && (
                                            courseArray && <Form.Select
                                                value={course}
                                                onChange={handleSelectChange}
                                                disabled={searchSource !== "course-content"}>
                                                <option>Select course</option>
                                                {courseArray.map(course => (
                                                    <option key={course.Course_ID} value={`${course.Course_ID}%%${course.Course_Name}`}>
                                                        {course.Course_Name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        )}

                                        {searchSource === "" &&
                                            <div style={{ height: "63px" }} className="pb-2">

                                            </div>
                                        }
                                        {searchSource !== "course-content" ? <p className='mt-2'>{culData.unit_name}</p> : <Form.Group controlId="unit" className='d-flex align-items-center pb-2 mt-3'>
                                            <Form.Select
                                                aria-label="Default select example"
                                                onChange={handleLessonChange}
                                                disabled={searchSource !== "course-content"}
                                            >
                                                <option>Select lesson</option>
                                                {lessonArray && lessonArray.map(lesson => (
                                                    <option key={lesson.Id} value={`${lesson.Id}%%${lesson.Lession_Name}%%${lesson.Lession_Content}`}>
                                                        {lesson.Lession_Name}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        </Form.Group>}
                                        <Form.Group className="py-2" controlId="Relevancy Prompt">
                                            <Form.Control as="textarea" placeholder="Relevancy Prompt" style={{ height: '180px', maxWidth: '100%' }} value={relavacyPrompt} onChange={handleRelavencyChange} />
                                        </Form.Group>
                                        <Form.Group controlId="maxResultToReturn" className="mb-2" >
                                            <div className="d-flex gap-3 mt-auto">
                                                <Form.Control type="text" value={minView} onChange={(e) => { checkMinView(e.target.value) }} autoComplete="off" placeholder='Minimum View' className="me-1" />
                                                <Form.Control type="text" value={MinLike} onChange={(e) => { checkMinLike(e.target.value) }} autoComplete="off" placeholder='Minimum Likes' className="ms-1" />
                                            </div>
                                        </Form.Group>
                                    </Form>
                                    <Form style={{ maxWidth: "30%", width: "100%" }}>
                                        <Col>
                                            <Form.Group controlId="" className="mb-2" >
                                                <div class="d-flex align-items-center">
                                                    <label class="me-3 mt-1">Saved Search:</label>
                                                    <div class="position-relative pb-2">
                                                        {/* <Form.Control type="text" value="" onChange="" autoComplete="off" placeholder='Enter Key word ' style={{ paddingLeft: "35px" }} /> */}
                                                        

                                                            <Select
                                                                className='mt-3'
                                                                onInputChange={handleInputChange}
                                                                options={options}
                                                                placeholder="Enter keyword"
                                                                isClearable
                                                                isSearchable
                                                                value={profile}
                                                                onMenuOpen={getAllProfile}
                                                                noOptionsMessage={() => "No options available"}
                                                                onChange={(selectedOption) => {
                                                                    setForm(selectedOption);
                                                                    setProfile(selectedOption);
                                                                }}
                                                            />
                                                    
                                                        {/* <div className="search-icon" style={{ position: "absolute", bottom: "15px", left: "10px" }}>
                                                            <i class="fas fa-search" style={{ color: "#bbb" }} />
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        {searchSource !== "course-content" ? <p className='mt-2'>{culData.lesson_name}</p> :
                                            lessonVideoUrls && <Form.Group controlId="course-url" className='d-flex align-items-center pb-2'>
                                                <Form.Select
                                                    onChange={pingUrlChange}
                                                >
                                                    <option>Select url</option>

                                                    {lessonVideoUrls.map(url => (
                                                        <option key={url} value={"https://www.youtube.com/embed/" + url}>
                                                            {"https://www.youtube.com/embed/" + url}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>}
                                        <Form.Group controlId="" className="pt-3 pb-2">
                                            <div key={'Restrict to Preferred Channels'} className="mb-1">
                                                <Form.Check // prettier-ignore
                                                    type="checkbox"
                                                    id=""
                                                    label="Restrict to Preferred Channels"
                                                    style={{ textAlign: "left" }}
                                                    onChange={handlePreferredChange}
                                                    checked={preferredChecked}
                                                />
                                            </div>
                                        </Form.Group>
                                        {/* <Form.Group controlId="unit" className='d-flex align-items-center pb-2'>
                                            {unitArray && <Form.Select
                                                aria-label="Default select example"
                                                onChange={handleUnitChange}
                                            >
                                                <option>Select unit</option>
                                                {unitArray.map(unit => (
                                                    <option key={unit.id} value={`${unit.id}%%${unit.name}`}>
                                                        {unit.name}
                                                    </option>
                                                ))}
                                            </Form.Select>}
                                        </Form.Group> */}
                                        <Form.Group controlId="prefferdchannel" className='d-flex align-items-center pb-1' >
                                            <Select
                                                isMulti
                                                name="prechannel"
                                                options={prefferdChannelList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onChange={handleChannelChange}
                                                placeholder="Select Channel"
                                                value={selectedPreChannels}
                                                isDisabled={!preferredChecked}
                                                styles={customStyles}
                                                ref={selectRef}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="" className="pb-1">

                                            <div key={'Save Search'} className="mb-1">
                                                <Form.Check
                                                    type="checkbox"
                                                    id="saveSearchCheckbox"
                                                    label="Save Search"
                                                    style={{ textAlign: "left" }}
                                                    onChange={handleSaveChange}
                                                    checked={saveChecked}

                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group controlId="savekey" className='d-flex align-items-center pb-2'>
                                            <Form.Control type="text" autoComplete="off" placeholder='Add Key Word' className="me-1" style={{ maxWidth: "100%" }}
                                                disabled={!saveChecked}
                                                onChange={handleSavekeyChange}
                                                value={savedkey}
                                                />
                                        </Form.Group>
                                        <Form.Group controlId="maxResultToReturn" className="mb-2" >
                                            <div className='d-flex gap-3 mt-2 align-items-center'>
                                                <Form.Control type="text" value={maxResult} onChange={(e) => { checkMaxResult(e.target.value) }} autoComplete="off" placeholder='Max Results' className="me-1" />
                                                <Col >
                                                    <Button variant="outline-dark" className="w-100" onClick={() => { handleReset() }} disabled={!courseId && !unitId && !query && !maxResult && !lessonContent && !lessonId}>
                                                        Clear
                                                    </Button>
                                                </Col>
                                                <Col >
                                                    <Button onClick={() => { handleClick() }} variant="primary" type="button" className="w-100">
                                                        Search
                                                    </Button>
                                                </Col>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                    {/* <Form className="mb-4 d-flex justify-content-end gap-2" style={{ maxWidth: "200px", width: "100%" }}>
                                        <Col className='d-flex flex-column'>
                                            <Form.Group controlId="maxResultToReturn" className="mb-2" >
                                                <Form.Control type="text" value={maxResult} onChange={(e) => { checkMaxResult(e.target.value) }} autoComplete="off" placeholder='Max Results' />
                                            </Form.Group>
                                            <div className='d-flex gap-3 mt-auto'>
                                                <Col >
                                                    <Button variant="outline-dark" className="w-100" onClick={() => { handleReset() }} disabled={!courseId && !unitId && !query && !maxResult && !lessonContent && !lessonId}>
                                                        Clear
                                                    </Button>
                                                </Col>
                                                <Col >
                                                    <Button onClick={() => { handleClick() }} variant="primary" type="button" className="w-100">
                                                        Search
                                                    </Button>
                                                </Col>
                                            </div>
                                        </Col>
                                    </Form> */}
                                </div>
                                <Row>
                                    <Col xs='12'>
                                        <Button hidden={!showSpinner} variant="link" className="mt-2 mb-5 btn-spinner ms-0 text-decoration-none" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className='me-1'
                                            />
                                            Search in progress...
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            {tableData && tableData.length !== 0 && <SearchListTable data={tableData} recentSearch={getSearchedList} status={setProcessStatus} />}
                        </div>
                    </Col>
                </Row>
            </Container>



        </section>




    );
};


export default ReplaceSeachComponents;
