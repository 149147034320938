import { Container } from "react-bootstrap";
import DefaultPromptForm from "../components/default-promt-form";

const VideoGuideDefaultSettings = () => {
    return (
        <Container className="my-3">
            <DefaultPromptForm title="Guide Chapters" promptType="VCHAPT" />
            <DefaultPromptForm title="Student Evaluation Note" promptType="VNOTE" />
            <DefaultPromptForm title="Guided chat" promptType="VCHATS" />
            <DefaultPromptForm title="Guide Content" promptType="VCONTENT" />
        </Container>
    );
}

export default VideoGuideDefaultSettings;