import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Row, Modal } from "react-bootstrap";

const EditPromptFormModal = ({ videoCode, prompt, promptType, onClose, enableChapterRegeneration }) => {
    const [saving, setSaving] = useState(false);
    const [value, setValue] = useState("");

    useEffect(() => {
        setValue(prompt)
    }, [prompt])

    const onSave = useCallback(() => {
        setSaving(true)
        axios.post(`${process.env.REACT_APP_API_URL}/video_prompt`, {
            "prompts": [{
                "prompt_type": promptType,
                "prompt": value,
            }],
            "video_url": `https://www.youtube.com/embed/${videoCode}`
        })
            .then(() => {
                onClose();
                enableChapterRegeneration();
            })
            .catch((err) => {
                console.log(err)
            }).finally(() => {
                setSaving(false)
            })
    }, [videoCode, value, promptType])

    return (
        <Modal show onHide={onClose} centered size="xl" style={{ zIndex: 10000 }}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Prompt</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflowY: "auto", maxHeight: "500px" }}>
                <Row className="my-3">
                    <Col xs="12">
                        <Form.Group controlId="" className="mb-2" >
                            <Form.Control
                                as="textarea"
                                placeholder="Prompt"
                                style={{ height: '180px' }}
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                disabled={saving}
                            />
                        </Form.Group>
                        <div style={{ display: 'flex', gap: '8px', justifyContent: 'end' }}>
                            <Button
                                variant="primary"
                                onClick={onSave}
                                disabled={saving || value === prompt}
                            >
                                {saving ? "Saving" : "Save Prompt"}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal >
    );
}

export default EditPromptFormModal;