import { useEffect, useRef, useState } from "react";
import { CloseButton, Overlay, Popover } from "react-bootstrap";
import ReactPlayer from "react-player";

const VideoPopover = ({ videoId, startTime, endTime, targetRef, onClose }) => {
    const ref = useRef();
    const timeout = useRef();
    const [playing, setPlaying] = useState(false);

    const startPlaying = () => {
        if (!startTime || !endTime) return;

        const startSeconds = Math.round(startTime * 100) / 100;
        const endSeconds = Math.round(endTime * 100) / 100;

        ref.current.seekTo(startSeconds, 'seconds');
        setPlaying(true);
        clearTimeout(timeout.current)
        timeout.current = setTimeout(() => {
            setPlaying(false);
        }, (endSeconds - startSeconds) * 1000)
    }

    useEffect(() => {
        startPlaying()
    }, [startTime, endTime])

    if (!videoId || !startTime || !endTime) {
        return <></>
    }

    return (
        <Overlay target={targetRef} show placement="top-end">
            {(props) => (
                <Popover id="popover-basic" {...props} style={{
                    ...props.style,
                    width: '500px',
                    maxWidth: '500px',
                }}>
                    <Popover.Body style={{
                        height: '300px',
                        maxHeight: '300px',
                        display: "flex",
                        flexDirection: "column",
                        gap: "12px",
                        alignItems: "end"
                    }}>
                        <CloseButton onClick={onClose} />
                        <ReactPlayer
                            ref={ref}
                            controls
                            url={`https://www.youtube.com/watch?v=${videoId}`}
                            width={"100%"}
                            height={"100%"}
                            playing={playing}
                            onReady={() => startPlaying()}
                            onPlay={() => {
                                if (!playing) {
                                    clearTimeout(timeout.current)
                                    setPlaying(true);
                                }
                            }}
                            onPause={() => {
                                if (playing) {
                                    clearTimeout(timeout.current)
                                    setPlaying(false);
                                }
                            }}
                        />
                    </Popover.Body>
                </Popover>
            )}
        </Overlay>
    );
}

export default VideoPopover;