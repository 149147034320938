import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

const DefaultPromptForm = ({ title, promptType }) => {
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [data, setData] = useState("");
    const [value, setValue] = useState("");
    const [editMode, setEditMode] = useState(false);

    const onSave = useCallback(() => {
        setSaving(true)
        axios.post(`${process.env.REACT_APP_API_URL}/prompt`, {
            "prompts": [{
                "prompt_type": promptType,
                "prompt": value,
            }],
            "activity_id": 1425
        })
            .then((response) => {
                if (response.data) {
                    setData(value);
                }
            })
            .catch((err) => {
                console.log(err)
            }).finally(() => {
                setSaving(false)
            })
    }, [value, promptType])

    useEffect(() => {
        if (!promptType) return
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/prompt?activity_id=1425&prompt_type=${promptType}`)
            .then((response) => {
                if (response.data) {
                    const [d] = response.data.data.filter(d => !!d.active)
                    setData(d.prompt);
                    setValue(d.prompt)
                }
            })
            .catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }, [promptType])

    return (
        <Row className="my-3">
            <Col xs="12">
                <div className="d-flex justify-content-between mb-2">
                    <span style={{ fontSize: "24px", fontWeight: "bold" }}>
                        {title}
                    </span>
                    <img
                        style={{ cursor: 'pointer' }}
                        src={"/images/edit.svg"}
                        alt="edit"
                        onClick={() => setEditMode(true)}
                    />
                </div>
                <Form.Group controlId="" className="mb-2" >
                    <Form.Control
                        as="textarea"
                        placeholder="Prompt"
                        style={{ height: '180px' }}
                        value={loading ? 'Loading' : value}
                        onChange={(e) => setValue(e.target.value)}
                        disabled={loading || saving || !editMode}
                    />
                </Form.Group>
                <div style={{ display: 'flex', gap: '8px', justifyContent: 'end' }}>
                    <Button
                        variant="primary"
                        onClick={onSave}
                        disabled={saving || value === data}
                    >
                        {saving ? "Saving" : "Save Prompt"}
                    </Button>
                </div>
            </Col>
        </Row>
    );
}

export default DefaultPromptForm;