import { useMemo } from 'react';
import Pagination from 'react-bootstrap/Pagination';

function Paginations({ current_page, page_size, total_page, total_records,clicked_page }) {
  

    const handlePaginationClick = (pageNumber) => {
        clicked_page(pageNumber);
    };

    const totalPages = Math.ceil(total_records / page_size);
    const records = useMemo(()=>{
        if (total_records){
            const pagination = [];
            let currentPage = current_page, totalKeys = totalPages
            currentPage = Math.max(1, Math.min(currentPage, totalKeys));
            const startPage = currentPage === 1 ? 1 : Math.max(1, currentPage - 8);
            const endPage = currentPage === 1 ? Math.min(totalKeys, currentPage + 8) : Math.min(totalKeys, currentPage + 8);
            for (let i = startPage; i <= endPage; i++) {
            pagination.push(i);
            }
        
            return pagination;
        }
        return []
    },[current_page,totalPages])
    const renderPageItems = ()=>{
        return records.map(record=>(
            <Pagination.Item
            key={record}
            active={record === current_page}
            onClick={() => handlePaginationClick(record)}
        >
            {record}
        </Pagination.Item>
        ))
    }

    return (
        <Pagination className='pagination-wrapper gap-2'>
            <Pagination.First
                disabled={current_page === 1}
                onClick={() => handlePaginationClick(1)}
            >
                <span aria-hidden="true">
                    <img src={"/images/chevron-left-double.svg"} alt="first" />
                </span>
            </Pagination.First>
            <Pagination.Prev
                disabled={current_page === 1}
                onClick={() => handlePaginationClick(current_page - 1)}
            >
                <span aria-hidden="true">
                    <img src={"/images/chevron-left.svg"} alt="prev" />
                </span>
            </Pagination.Prev>
            {!([1,2].includes(current_page)) && <Pagination.Ellipsis />}
            {renderPageItems()}
            {!([totalPages-1,totalPages].includes(current_page)) && <Pagination.Ellipsis />}
            <Pagination.Next
                className="custom-next-btn"
                disabled={current_page === totalPages}
                onClick={() => handlePaginationClick(current_page + 1)}
            >
                <span aria-hidden="true">
                    <img src={"/images/chevron-right.svg"} alt="next" />
                </span>
            </Pagination.Next>
            <Pagination.Last
                disabled={current_page === totalPages}
                onClick={() => handlePaginationClick(totalPages)}
            >
                <span aria-hidden="true">
                    <img src={"/images/chevron-right-double.svg"} alt="last" />
                </span>
            </Pagination.Last>
        </Pagination>
    );
}

export default Paginations;