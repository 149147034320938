import { Button, Modal } from "react-bootstrap";

const DeleteConfirmationModal = ({ loading, onClose, onConfirm }) => {
    return (
        <Modal show onHide={onClose} centered size="lg" style={{ zIndex: 10000 }}>
            <Modal.Header >
                <Modal.Title>Confirm Deletion
                </Modal.Title>
            </Modal.Header>
            <Modal.Body >
                Are you sure you want to delete? This action cannot be undone.
            </Modal.Body>
            <Modal.Footer style={{ overflowY: "auto", maxHeight: "500px" }}>
                <Button variant="secondary" onClick={onClose} disabled={loading}>Close</Button>
                <Button variant="primary" onClick={onConfirm} disabled={loading}>{loading ? 'Deleting' : 'Confirm'}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteConfirmationModal;