import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const ViewQuestionsModal = ({ videoCode, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        if (!videoCode) return;
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/video_guide?video_url=https://www.youtube.com/embed/${videoCode}`)
            .then((response) => {
                if (response.data) {
                    setData(response.data.data.subtopics);
                }
            })
            .catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }, [videoCode])

    const [editMode, setEditMode] = useState([]);
    const [saving, setSaving] = useState([])
    const onSave = useCallback((val, key) => {
        setSaving((prev) => [...prev, key])
        axios.put(`${process.env.REACT_APP_API_URL}/video_guide/questions`, {
            video_url: `https://www.youtube.com/embed/${videoCode}`,
            ...val,
        })
            .then((response) => {
                setEditMode((prev) => prev.filter(v => v != key))
            })
            .catch((err) => {
                console.log(err)
            }).finally(() => {
                setSaving((prev) => prev.filter(v => v != key))
            })
    }, [videoCode])

    return (
        <Modal show onHide={onClose} centered size="xl" style={{ zIndex: 10000 }}>
            <Modal.Header closeButton>
                <Modal.Title>View Question</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflowY: "auto", maxHeight: "500px" }}>
                {loading && "Loading..."}
                {data.map((row, j) => (
                    <div style={{ margin: '16px 0' }}>
                        <div style={{ fontWeight: 'bold' }}>{row.heading}</div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px', marginTop: '8px' }}>
                            {Object.entries(row.augmentation.hints).map(([key, val], i) => (
                                <div style={{ display: 'flex', gap: '8px', alignItems: "start" }}>
                                    {!editMode.includes(`${row.segmentId}_${key}`) ?
                                        <>
                                            <img
                                                style={{ cursor: 'pointer' }}
                                                src={"/images/edit.svg"}
                                                alt="edit"
                                                onClick={() => setEditMode((prev) => [...prev, `${row.segmentId}_${key}`])}
                                            />
                                            {i + 1}. {val.hint}
                                        </>
                                        :
                                        <>
                                            <img
                                                style={{ cursor: 'pointer' }}
                                                src={"/images/save.svg"}
                                                alt="save"
                                                onClick={() => saving.includes(`${row.segmentId}_${key}`) ? {} : onSave({
                                                    oeh_key: key,
                                                    segment_id: row.segmentId,
                                                    question: val.hint,
                                                }, `${row.segmentId}_${key}`)}
                                            />
                                            {i + 1}. <textarea
                                                style={{ width: "100%", border: '1px solid gray' }}
                                                disabled={saving.includes(i)}
                                                value={val.hint}
                                                onChange={(e) => {
                                                    setData((prev) => {
                                                        const newData = [...prev]
                                                        newData[j].augmentation.hints[key].hint = e.target.value;
                                                        return newData;
                                                        // newData[i] = {
                                                        //     ...newData[i],
                                                        //     text: e.target.value,
                                                        // }
                                                        // return newData;
                                                    })
                                                }}
                                            />
                                        </>
                                    }

                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </Modal.Body>
        </Modal>
    );
}

export default ViewQuestionsModal;